@import "~@/erp/styles/variables/variables.scss";






















































































.mr-15 {
  margin-right: 15px;
}

.pro-item {
  .pro-title {
    font-size: 16px;
    font-family: 'Arial Normal', 'Arial', sans-serif;
    color: #333333;
    font-weight: 400;
  }

}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;

  i {
    font-size: 120px;
  }

  span {
    margin-top: 10px;
    color: #909399;
    font-size: 16px;
    font-weight: 500;
  }
}

.img-list {
  max-height: 400px;
  overflow: auto;

  .img-item {
    height: 100px;
    width: 100px;
    border-radius: 6px;
    display: inline-block;
    margin: 5px;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      border-radius: 6px;
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 999;
      background-color: rgba(103, 117, 111, 0.5);
      text-align: center;
      border-radius: 6px;

      i {
        position: absolute;
        right: 5px;
        bottom: 5px;
        color: #88d805;
        font-size: 18px;
      }
    }
  }

}
