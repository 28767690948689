@import "~@/erp/styles/variables/variables.scss";




































































::v-deep .el-dialog .el-dialog__body {
  padding-top: 0;
}
