@import "~@/erp/styles/variables/variables.scss";


































































































































































































































































































































































































































































































































































































































































































































// 是否退税确认弹框
.drawbackDialog {
  .el-dialog__body {
    padding-top: 0;
    .content {
      display: flex;
      i {
        color: #ffba00;
        font-size: 24px;
        margin-right: 16px;
      }
      p:first-of-type {
        font-weight: bold;
      }
    }
  }
}
