@import "~@/erp/styles/variables/variables.scss";


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.order-content {
  padding: 0 20px 80px;

  h4 {
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-family: 'PingFang Bold';
    color: #606266;
    margin-left: -12px;
  }

  .ml-12 {
    margin-left: -12px;
  }

  .el-input,
  .el-select {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .product-list {
    .product-title {
      display: flex;
      justify-content: flex-start;

      .el-form-item {
        width: 500px;
        margin-left: 20px;
      }
    }

    .product-table {
      display: flex;
      justify-content: space-between;

      table tbody tr {
        height: 90px;

        td {
          height: 90px;
          line-height: 16px;
          padding: 5px 10px;
        }

        .limit {
          height: 100%;
          overflow-y: auto;
        }
      }

      .l-table {
        overflow-x: auto;

        table {
          width: 100%;
          min-width: 1800px;
        }
      }

      .r-table {
        table {
          width: 200px;
        }
      }
    }

    .total-money {
      line-height: 40px;
      text-align: right;

      span {
        font-family: 'PingFang Bold';
        font-size: 18px;
        margin-left: 10px;
      }
    }
  }
}

.oper-btn {
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 20px 0;
  background: white;
}

.address-dialog {
  .container {
    .box {
      display: flex;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      border-left: 1px solid #ececec;
      border-right: 1px solid #ececec;

      >div:first-child {
        width: 80%;
        padding: 0 20px;
        border-right: 1px solid #ececec;
        border-bottom: 1px solid #ececec;

        input {
          vertical-align: middle;
          margin-right: 5px;
          cursor: pointer;
        }
      }

      >div:last-child {
        width: 20%;
        text-align: center;
        border-bottom: 1px solid #ececec;
      }
    }

    .box:first-child {
      border-top: 1px solid #ececec;
    }
  }
}

td .link {
  cursor: pointer;
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

::v-deep .el-input-number--small {
  width: 80px !important;
}

::v-deep .el-input-number--small .el-input__inner {
  padding: 0 10px !important;
}

::v-deep .el-input-number--small .el-input-number__increase,
::v-deep .el-input-number--small .el-input-number__decrease {
  display: none !important;
}

::v-deep .el-row.bg {
  margin-right: 0px !important;
  margin-left: 0px !important;
  width: calc(100% - 100px) !important;
  // background: rebeccapurple;
}

.detail-block {
  border: 1px solid #ebeef5;
  padding: 20px 30px 0 30px;

  .detail-item {
    height: 36px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    & span {
      flex: 1;
      font-size: 14px;
    }

    & .detail-label {
      color: #999999;
    }

    & .detail-content {
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-family: 'PingFang Bold';
      color: #303133;
    }
  }
}

.el-tag {
  position: absolute;
  top: 0;
  left: 192px;

  &.el-tag-other {
    top: 4px;
    right: 4px;
    left: auto;
    z-index: 2;
    background-color: #fff7e8;
    border-color: #ffefd1;
    color: #ffae18;
  }
}

.button-wrapper {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 99;
}
