@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































































.list-wrapper {
  border: 1px solid #eee;

  .header {
    background-color: #f6f8f9;

    .el-col {
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      padding: 5px;

      &:last-child {
        border-right: none;
      }
    }
  }

  .product-wrapper {
    padding: 10px 10px 15px 8px;

    p {
      padding-left: 5px;
    }

    /* .img-list {
      .img-wrapper {
        height: 100px;
        width: 100px;
        display: inline-block;
        margin: 5px;
        position: relative;
        img {
          height: 100%;
          width: 100%;
        }
        .mask {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 999;
          background-color: rgba(0, 0, 0, 0.8);
        }
        &:hover {
          div {
            display: block;
          }
        }
      }
    } */
  }

  .upload-image {
    position: absolute;
    left: -20000px;
  }

  .c555 {
    color: #555;
  }

  .c333 {
    color: #333;
  }

  .c999 {
    color: #999;
  }

  .tl {
    text-align: left;
  }

  .tc {
    text-align: center;
  }

  .tr {
    text-align: right;
  }

  .fb {
    font-weight: 600;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .pr-10 {
    padding-right: 10px;
  }

  .mr-15 {
    margin-right: 15px;
  }
}
