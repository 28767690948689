@import "~@/erp/styles/variables/variables.scss";










































































































.wpre {
  white-space: pre;
}
.table-wrapper {
  padding-bottom: 20px;
}
