@import "~@/erp/styles/variables/variables.scss";




























































































































































































































































































































































































































.el-form-item {
  margin-bottom: 0px !important;
}
.add-style {
  border: 1px solid #eee;
  border-top: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
::v-deep .el-input-number {
  width: 80px !important;
}
.exceed-tip {
  position: absolute;
  left: 10px;
  bottom: -2px;
  font-size: 12px;
  color: red;
}
